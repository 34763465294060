import { useEffect } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'shared/utils/url/useQuery'
import locales from 'infrastructure/i18n'
import { DataFieldSelection } from '@yes.technology/react-toolkit'

type SelectionOption = Array<{
  id: string
  value: string
  label?: string
}>

const Language = () => {
  const { t, language, setLanguage } = useTranslation()

  const navigate = useNavigate()

  const handleChangeLanguage = ([{ id: newLanguage }]: SelectionOption) => {
    setLanguage(newLanguage)
    navigate('?lang=' + newLanguage)
  }

  const options = Object.keys(locales).map((localeKey) => ({
    id: localeKey,
    value: localeKey,
    label: locales[localeKey]['language-name'] as string
  }))

  const selectedLanguage = options.filter((option) => option.id === language)

  return (
    <>
      <SetLang />
      <DataFieldSelection
        label={t('language.label')}
        options={options}
        selected={selectedLanguage}
        onChange={handleChangeLanguage}
        required
      />
    </>
  )
}

const SetLang = () => {
  const query = useQuery()
  const lang = query.get('lang')
  const { setLanguage } = useTranslation()

  useEffect(() => {
    if (lang) {
      setLanguage(lang)
    }
  }, [lang, setLanguage])

  return null
}

export default Language
